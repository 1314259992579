import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { ORDER_STATUS } from "../../../../config/global";

const dataMap = {
  ddmp: {
    main: {
      endpoint: "role_producer_orders_ddmp",
      filters: {
        statusId: "detailsv__status",
        productTypeId: "detailsv__tp",
        productLineId: "detailsv__tpline",
        producerId: "id",
        productId: "detailsv__ppm",
        orderId: "detailsv__morder",
        islandId: "detailsv__ddmi",
      },
      search: [
        "detailsv__ppl__translations__name",
        "detailsv__pp__translations__name",
        "detailsv__morder__order__id",
        "detailsv__pname",
        "detailsv__iname",
        "detailsv__fname",
      ],
    },
    panel1: {
      list: {
        endpoint: "role_producer_orders_pdetail",
        filters: {
          statusId: "status",
          productTypeId: "detailsv__tp",
          productLineId: "detailsv__tpline",
          producerId: "ddmp",
          productId: "ppm",
          orderId: "detailsv__morder",
          islandId: "detailsv__ddmi",
        },
        search: [
          "detailsv__ppl__translations__name",
          "detailsv__pp__translations__name",
          "detailsv__morder__order__id",
          "detailsv__pname",
          "detailsv__iname",
          "detailsv__fname",
        ],
      },
      detail: {
        endpoint: "role_producer_orders_detail",
        filters: {
          statusId: "status",
          productTypeId: "detailsv__tp",
          productLineId: "detailsv__tpline",
          producerId: "ddmp",
          productId: "ppm",
          orderId: "order",
          islandId: "detailsv__ddmi",
        },
        search: [
          "detailsv__ppl__translations__name",
          "detailsv__pp__translations__name",
          "detailsv__morder__order__id",
          "detailsv__pname",
          "detailsv__iname",
          "detailsv__fname",
        ],
      },
    },
    panel2: {
      list: {
        endpoint: "role_producer_orders_plist",
        filters: {
          statusId: "detailsv__status",
          productTypeId: "detailsv__tp",
          productLineId: "detailsv__tpline",
          producerId: "detailsv__ddmp",
          productId: "detailsv__ppm",
          orderId: "detailsv__morder",
          islandId: "detailsv__ddmi",
        },
        search: [
          "detailsv__ppl__translations__name",
          "detailsv__pp__translations__name",
          "detailsv__morder__order__id",
          "detailsv__pname",
          "detailsv__iname",
          "detailsv__fname",
        ],
      },
      detail: {
        endpoint: "role_producer_orders_detail",
        filters: {
          statusId: "status",
          productTypeId: "detailsv__tp",
          productLineId: "detailsv__tpline",
          producerId: "ddmp",
          productId: "ppm",
          orderId: "order",
          islandId: "detailsv__ddmi",
        },
        search: [
          "detailsv__ppl__translations__name",
          "detailsv__pp__translations__name",
          "detailsv__morder__order__id",
          "detailsv__pname",
          "detailsv__iname",
          "detailsv__fname",
        ],
      },
    },
    statusCode: {
      canceled: ORDER_STATUS.CANCELED,
      next: ORDER_STATUS.MARKET_ARRIVED,
      prev: ORDER_STATUS.PRODUCER_IN_CHARGE,
    },
  },

  ddmi: {
    main: {
      endpoint: "role_island_orders_ddmi",
      filters: {
        statusId: "detailsv__status",
        productTypeId: "detailsv__tp",
        productLineId: "detailsv__tpline",
        producerId: "detailsv__ddmp",
        productId: "detailsv__ppm",
        orderId: "detailsv__morder",
        islandId: "ddmi",
      },
      search: [
        "detailsv__ppl__translations__name",
        "detailsv__pp__translations__name",
        "detailsv__morder__order__id",
        "detailsv__pname",
        "detailsv__iname",
        "detailsv__fname",
      ],
    },
    panel1: {
      list: {
        endpoint: "role_island_orders_mlist",
        filters: {
          statusId: "detailsv__status",
          productTypeId: "detailsv__tp",
          productLineId: "detailsv__tpline",
          producerId: "detailsv__ddmp",
          productId: "detailsv__ppm",
          orderId: "order",
          islandId: "detailsv__ddmi",
        },
        search: [
          "detailsv__ppl__translations__name",
          "detailsv__pp__translations__name",
          "order__id",
          "detailsv__pname",
          "detailsv__iname",
          "detailsv__fname",
        ],
      },
      detail: {
        endpoint: "role_island_orders_detail",
        filters: {
          statusId: "status",
          productTypeId: "detailsv__tp",
          productLineId: "detailsv__tpline",
          producerId: "ddmp",
          productId: "ppm",
          orderId: "order",
          islandId: "detailsv__ddmi",
        },
        search: [
          "detailsv__ppl__translations__name",
          "detailsv__pp__translations__name",
          "detailsv__morder__order__id",
          "detailsv__pname",
          "detailsv__iname",
          "detailsv__fname",
        ],
      },
    },
    statusCode: {
      canceled: ORDER_STATUS.CANCELED,
      next: ORDER_STATUS.ISLAND_ARRIVED,
      prev: ORDER_STATUS.MARKET_IN_CHARGE,
    },
  },

  ddmiIsland: {
    statusCode: {
      canceled: ORDER_STATUS.CANCELED,
      next: ORDER_STATUS.FAMILY_IN_CHARGE,
      prev: ORDER_STATUS.ISLAND_IN_CHARGE,
    },
  },

  incident: {
    main: {
      endpoint: "role_orders_incident",
      filters: {
        warehouseId: "detailsv__wh",
        statusId: "detailsv__status",
        productTypeId: "detailsv__tp",
        productLineId: "detailsv__tpline",
        producerId: "detailsv__pp__producer_id",
        productId: "detailsv__ppm",
        orderId: "detailsv__morder",
        islandId: "detailsv__ddmi",
      },
      search: [
        "detailsv__ppl__translations__name",
        "detailsv__pp__translations__name",
        "detailsv__morder__order__id",
        "detailsv__pname",
        "detailsv__iname",
        "detailsv__fname",
      ],
    },
    panel1: {
      list: {
        endpoint: "role_orders_incident_details",
        filters: {
          warehouseId: "detailsv__wh",
          statusId: "status",
          productTypeId: "detailsv__tp",
          productLineId: "detailsv__tpline",
          producerId: "ddmp",
          productId: "ppm",
          orderId: "detailsv__morder",
          islandId: "detailsv__ddmi",
        },
        search: [
          "detailsv__ppl__translations__name",
          "detailsv__pp__translations__name",
          "detailsv__morder__order__id",
          "detailsv__pname",
          "detailsv__iname",
          "detailsv__fname",
        ],
      },
    },
  },
};

const filtersMap = (query = "", defaultValue = undefined) => {
  const result = get(dataMap, query, defaultValue);
  return cloneDeep(result);
};

export { filtersMap };
